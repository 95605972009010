#root {
  min-height: 100vh;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(128, 128, 128, 1);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(128, 128, 128, 0);
  }
}

*:focus {
  outline: none;
}

::-webkit-scrollbar-track {
  background: transparent !important;
  width: 0 !important;
}
