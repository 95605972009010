.str-chat {
  height: 100%;
}

.str-chat-channel-list {
  float: none;
}

.str-chat.str-chat-channel.messaging {
  background: #fafafa;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06), 0px 2px 30px rgba(0, 0, 0, 0.1);
}

.dark.str-chat.str-chat-channel.messaging {
  background: #212326;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
}

.str-chat.str-chat-channel-list.messaging {
  background: #fafafa;
  height: 100%;
}

.dark.str-chat.str-chat-channel-list.messaging {
  background: #212326 !important;
}

.str-chat-channel .str-chat__container {
  padding-top: 0;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0 !important;
  min-width: 250px;
}

.messaging.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
  padding-top: 0;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 30px 30px 0 0px;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) {
  padding: 20px 0 0 0px;
}

.str-chat-channel.messaging
  .str-chat__main-panel:not(:last-child)
  .messaging__channel-header {
  border-radius: 10px 0px 0px 0px;
}

/* .str-chat__message-simple-status {
  display: none;
} */

.messaging.str-chat.dark .str-chat__message--system__date {
  color: rgba(255, 255, 255, 0.7);
}

.messaging.str-chat.dark .str-chat__message--system__text p {
  color: rgba(255, 255, 255, 0.7);
}

.messaging.str-chat.dark .str-chat__message--system__line {
  background: rgba(255, 255, 255, 0.1);
}

.str-chat__message--system {
  padding: 20px;
}

/* Mobile View */
@media screen and (max-width: 640px) {
  /*
   * This is to prevent the browser navigation bars from hiding the app
   * on some mobile browsers. The app-height variable is set dynamically
   * using javascript.
   */
  .str-chat-channel {
    height: var(--app-height);
  }

  .str-chat-channel-list.messaging {
    float: unset;
  }

  #mobile-channel-list {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(-100vw, 0, 0);
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  }

  #mobile-channel-list.show {
    transform: translate3d(0vw, 0, 0);
    z-index: 1000;
  }
}

/* To fix inherited styles (as per Team and Customer Support apps */
@media screen and (max-width: 960px) {
  .str-chat-channel-list.messaging {
    position: unset;
    left: unset;
    top: unset;
    z-index: unset;
    min-height: unset;
    overflow-y: unset;
    box-shadow: unset;
    transition: unset;
  }
}

/* OVERRIDES */

.messaging.str-chat .str-chat__thread {
  margin-top: 0;
  margin-right: 0;
  border-radius: 0;
}

.dark.str-chat.str-chat-channel-list.messaging {
  background: none !important;
  box-shadow: none !important;
}

.str-chat.str-chat-channel-list.messaging {
  background: none !important;
  box-shadow: none !important;
}

.dark.str-chat.str-chat-channel.messaging {
  background: none !important;
  box-shadow: none !important;
}

.str-chat.str-chat-channel.messaging {
  background: none !important;
  box-shadow: none !important;
}

.str-chat.dark .messaging__channel-header {
  background: none !important;
  box-shadow: none !important;
}

.messaging__channel-header {
  background: none !important;
  box-shadow: none !important;
}

.messaging.str-chat .str-chat__list {
  background: none !important;
  box-shadow: none !important;
  padding-bottom: 20px;
}

.messaging.str-chat.dark .str-chat__list {
  background: none !important;
  box-shadow: none !important;
}

.str-chat.dark .str-chat__messaging-input {
  background: none !important;
  box-shadow: none !important;
  border-top: none !important;
}

.str-chat__messaging-input {
  background: none !important;
  box-shadow: none !important;
  border-top: none !important;
}

.str-chat.dark .messaging-input__input-wrapper {
  border: none !important;
  background: none !important;
}

.messaging-input__input-wrapper {
  border: none !important;
  background: none !important;
}

.str-chat.dark .str-chat__messaging-input .str-chat__textarea textarea {
  background: transparent;
  /* border: none !important; */
}

.str-chat__messaging-input .str-chat__textarea textarea {
  background: transparent;
  /* border: none !important; */
}

.messaging.str-chat.dark .str-chat__thread {
  background: none !important;
  border-left: none !important;
}

.messaging.str-chat .str-chat__thread {
  background: none !important;
  border-left: none !important;
}

.str-chat.dark .custom-thread-header {
  background: none !important;
  box-shadow: none !important;
}

.custom-thread-header {
  background: none !important;
  box-shadow: none !important;
}

.str-chat.dark .channel-preview__container.selected {
  /* background: none !important; */
  box-shadow: none !important;
}

.channel-preview__container.selected {
  /* background: none !important; */
  box-shadow: none !important;
}

.str-chat.dark .messaging__channel-list__header__button {
  background: none !important;
  box-shadow: none !important;
}

.messaging__channel-list__header__button {
  background: none !important;
  box-shadow: none !important;
}

.dark.str-chat .str-chat__message-actions-box {
  box-shadow: none !important;
  /* background: black !important; */
}

.str-chat__message-actions-box {
  box-shadow: none !important;
  /* background: black !important; */
}

.str-chat.dark .str-chat__modal.str-chat__modal--open {
  background: rgba(0, 0, 0, 0.8);
}

/******* MODAL ********/

.str-chat__modal__close-button {
  position: absolute;
  top: calc(50% - 130px);
  right: 0;
  left: 0;
  padding: var(--md-p);
  font-size: var(--md-font);
  line-height: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
}

.str-chat__edit-message-form {
  position: relative;
  top: 0px;
}

.str-chat__modal .str-chat__edit-message-form textarea {
  max-height: none !important;
}

.str-chat__fileupload-wrapper {
  display: none !important;
}
